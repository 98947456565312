import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import imagesLoaded from 'imagesloaded';

// store
import { instances, wrap } from './store';

export default class Intro {
  constructor() {
    this.dom = {
      page: document.querySelector('.a-page'),
    };
  }

  init() {
    if (instances.loading === true) {
      this.set();
    }
  }

  set = () => {
    imagesLoaded(this.dom.page, () => {
      if (this.dom.page.classList.contains('is-work')) {
        document.fonts.ready.then(() => {
          this.work();
        });
      }
      else if (this.dom.page.classList.contains('is-bio')) {
        document.fonts.ready.then(() => {
          this.bio();
        });
      } else {
        instances.loading = false;
        document.body.classList.remove('is-preloading');
      }
    });
  }

  bio = () => {
    const i = document.querySelector('#h-bio img');
    const m = document.querySelectorAll('#a-menu a');
    const f = document.querySelector('#h-bio .figure');
    const s = document.querySelectorAll('.bottom-r a');

    const f5 = new SplitText(document.querySelectorAll('#h-bio .f-5'), {
      type: 'lines'
    });

    const f7 = new SplitText(document.querySelectorAll('#h-bio .f-7'), {
      type: 'lines'
    });

    const p = new SplitText(document.querySelectorAll('#h-bio p'), {
      type: 'lines'
    });

    [...f7.lines].forEach((c) => {
      wrap.line(c);
    });

    [...f5.lines].forEach((c) => {
      wrap.line(c);
    });

    [...p.lines].forEach((c) => {
      wrap.line(c);
    });

    const l = document.querySelectorAll('#h-bio .line div');

    gsap.set(f, {
      opacity: 0,
    });

    gsap.set([l, i, m, s], {
      yPercent: 102,
    });

    gsap.to(f, {
      opacity: 1,
      duration: 2,
    });

    gsap.to([m, i, l, s], {
      yPercent: 0,
      duration: 2,
      stagger: 0.1,
      ease: 'expo.out',
      clearProps: 'all',
      onComplete: () => {
        instances.loading = false;
      }
    });

    document.body.classList.remove('is-preloading');
  }

  work = () => {
    const i = document.querySelector('#h-hero img');
    const m = document.querySelectorAll('#a-menu a');

    const f5 = new SplitText(document.querySelectorAll('#h-hero .f-5'), {
      type: 'lines'
    });

    const f7 = new SplitText(document.querySelectorAll('#h-hero .f-7'), {
      type: 'lines'
    });

    const p = new SplitText(document.querySelectorAll('#h-hero p'), {
      type: 'lines'
    });

    [...f7.lines].forEach((c) => {
      wrap.line(c);
    });

    [...f5.lines].forEach((c) => {
      wrap.line(c);
    });

    [...p.lines].forEach((c) => {
      wrap.line(c);
    });

    const l = document.querySelectorAll('#h-hero .line div');

    gsap.set([l, i, m], {
      yPercent: 102,
    });

    gsap.to([m, i, l], {
      yPercent: 0,
      duration: 2,
      stagger: 0.1,
      ease: 'expo.out',
      clearProps: 'all',
      onComplete: () => {
        instances.loading = false;
      }
    });

    document.body.classList.remove('is-preloading');
  }
}
