// styles
import './index.scss';

// application
import Sniffer from 'sniffer';
import Highway from '@dogstudio/highway';

// renderers
import Renderer from './renderer/renderer';

// transitions
import Back from './transitions/back';
import Campaign from './transitions/campaign';
import Contact from './transitions/contact';
import Default from './transitions/default';
import Header from './transitions/header';
import Work from './transitions/work';

// intro
import Intro from './utils/intro';

// components
import Menu from './components/menu/menu';

// instances
import { instances } from './utils/store';

// sniffer
Sniffer.addClasses(document.documentElement);

// ios fix
if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
  document.querySelector('html').classList.add('is-tablet', 'is-device');
  document.querySelector('html').classList.remove('is-desktop');

  instances.ipad = true;

  if (Sniffer.isPhone) {
    document.querySelector('html').classList.remove('is-tablet');
  }
}

// init
const intro = new Intro();
intro.init();

const menu = new Menu();
menu.init();

// core
const H = new Highway.Core({
  renderers: {
    renderer: Renderer
  },
  transitions: {
    default: Default,
    contextual: {
      work: Work,
      back: Back,
      header: Header,
      contact: Contact,
      campaign: Campaign,
    }
  }
});

// viewport
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// nav
const l = document.querySelectorAll('#a-menu a');

H.on('NAVIGATE_IN', ({ to, location }) => {
  [...l].forEach((i) => {
    i.classList.remove('is-active');
    if (i.href === location.href) {
      i.classList.add('is-active');
    }
  });
});
