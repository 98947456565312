import gsap from 'gsap';
import Highway from '@dogstudio/highway';

class Contact extends Highway.Transition {
  in({ from, to, done }) {

    this.window = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    this.menu = {
      d: document.querySelector('.menu-d'),
      l: document.querySelector('.menu-l'),
    };

    this.dom = {
      heading: to.querySelector('#h-contact .f-2'),
    }

    const a = to.querySelectorAll('header a');
    const em = to.querySelectorAll('header em');

    gsap.set([a, em], {
      yPercent: 102,
    });

    gsap.to(a, {
      delay: 0.8,
      yPercent: 0,
      duration: 2,
      stagger: 0.1,
      ease: 'expo.out',
      clearProps: 'all',
    });

    gsap.to(em, {
      delay: 0.8,
      yPercent: 0,
      duration: 2,
      stagger: 0.1,
      ease: 'expo.out',
      clearProps: 'all',
    });

    // bio
    if (from.classList.contains('is-bio')) {
      gsap.fromTo(this.menu.l,
        {
          clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
        }, {
        duration: 1.5,
        ease: 'expo.inOut',
        clip: `rect(0px ${this.window.w}px 0px 0px)`,
      });

      gsap.fromTo(this.menu.d,
        {
          clip: `rect(${this.window.h}px ${this.window.w}px ${this.window.h}px 0px)`,
        }, {
        duration: 1.5,
        ease: 'expo.inOut',
        clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
      });
    }

    gsap.fromTo(this.dom.heading, {
      yPercent: 100,
    }, {
      delay: 0.75,
      yPercent: 0,
      duration: 1.5,
      ease: 'expo.out',
      clearProps: 'all',
    });

    gsap.fromTo(from,
      {
        clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
      }, {
      duration: 1.5,
      clearProps: 'all',
      ease: 'expo.inOut',
      clip: `rect(0px ${this.window.w}px 0px 0px)`,

      onComplete: () => {
        from.remove();
        done();
      },
    }
    );
  }

  out({ from, done }) {
    done();
  }
}

export default Contact;
