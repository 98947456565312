import gsap from 'gsap';
import Highway from '@dogstudio/highway';

class Work extends Highway.Transition {
  in({ from, to, done }) {

    this.window = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    this.menu = {
      e: document.getElementById('a-menu'),
      d: document.querySelector('.menu-d'),
      l: document.querySelector('.menu-l'),
      a: document.querySelectorAll('#a-menu a'),
    };

    if (this.back) {
      to.querySelector('#s-player .video').href = this.back;
      this.back = undefined;
    }

    gsap.to(this.menu.a, {
      yPercent: -100,
    });

    gsap.fromTo(from,
      {
        clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
      }, {
      duration: 1.5,
      clearProps: 'all',
      ease: 'expo.inOut',
      clip: `rect(0px ${this.window.w}px 0px 0px)`,

      onComplete: () => {
        from.remove();
        done();
      },
    }
    );
  }

  out({ from, done, trigger }) {
    if (trigger.hasAttribute('data-back')) {
      this.back = trigger.dataset.back;
    }
    done();
  }
}

export default Work;
