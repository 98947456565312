import gsap from 'gsap';
import Sniffer from 'sniffer';

import { instances } from '../../utils/store';

export default class Thumbnail {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.blocks = document.querySelectorAll('.block');
    this.thumbnails = document.querySelectorAll('.thumbnail');

    this.state = {
      hover: null,
      title: null,
      bounding: null,
    }

    this.mouse = {
      t: 0,
      p: 0,
    };

    this.y = {
      current: 0,
    };

    this.window = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  init = () => {
    if (Sniffer.isDevice || instances.ipad === true) {
      this.device();
      return;
    }

    this.set();
    this.events();
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  device = () => {
    this.request = new XMLHttpRequest();
    this.thumbnails = document.querySelectorAll('[data-src]');

    [...this.thumbnails].forEach((el) => {
      el.addEventListener('click', this.load);
    });
  }

  load = (e) => {
    this.data = e.target.closest('.block').dataset.src;

    this.request.open('POST', '' + document.location.origin + '/wp-admin/admin-ajax.php', true);
    this.request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');

    this.request.onload = () => {
      this.player = document.createElement('div');
      this.player.id = 'a-player';
      this.player.innerHTML = this.request.response;

      this.video = this.player.querySelector('video');

      document.body.appendChild(this.player);

      this.native();
    };

    this.request.send('action=player&id=' + this.data + '');
  }

  native = () => {
    this.video.addEventListener('webkitendfullscreen', () => {
      this.hide();
    });

    if (Sniffer.isDroid) {
      this.video.requestFullscreen();
      this.video.addEventListener('fullscreenchange', () => {
        if (!document.fullscreenElement) {
          this.hide();
          this.video = undefined;
        }
      });
    }
  }

  hide = () => {
    this.player.remove();
    this.player = null;
  }

  set = () => {
    if (instances.offset < 0) return;
    if (this.page.classList.contains('is-campaign')) return;

    gsap.set(this.blocks, {
      opacity: 0,
      y: this.window.width * 0.1,
    });
  }

  events = () => {
    gsap.ticker.add(this.render);

    window.addEventListener('mousemove', (e) => {
      this.mouse.t = e.clientY;
    });

    [...this.blocks].forEach((el) => {
      el.addEventListener('click', this.click);
      el.addEventListener('mouseenter', this.enterBlock);
      el.addEventListener('mouseleave', this.leaveBlock);
    });

    [...this.thumbnails].forEach((el) => {
      el.addEventListener('mouseenter', this.enterVideo);
      el.addEventListener('mouseleave', this.leaveVideo);
    });
  }

  render = () => {
    this.follower();
    if (this.page.classList.contains('is-campaign')) return;
    this.inview();
  }

  follower = () => {
    if (this.state.title === null) return;

    this.state.bounding = this.state.hover.getBoundingClientRect().top;

    this.mouse.p += ((this.mouse.t - this.mouse.p) * 0.1);
    this.state.title.style.transform = `translate3d(0px, ${this.mouse.p - this.state.bounding}px, 0px)`;
  }

  inview = () => {
    if (instances.offset < 0) return;

    this.y.current = Math.abs(instances.scroll.data.last);

    [...this.blocks].forEach((el) => {
      const i = el;
      const b = el.getBoundingClientRect();

      if (b.top < this.window.height && el.inview === undefined) {
        i.inview = true;

        gsap.to(i, {
          y: 0,
          opacity: 1,
          duration: 3,
          ease: 'expo.out',
        });
      }
    });
  }

  click = () => {
    if (this.page.classList.contains('is-campaign')) return;
    instances.offset = instances.scroll.data.current;
  }

  enterBlock = (e) => {
    this.state.hover = e.target;
    this.state.title = e.target.querySelector('.title');

    gsap.to(this.state.title, {
      opacity: 1,
    });
  }

  leaveBlock = () => {
    gsap.to(this.state.title, {
      opacity: 0,
    });

    this.state.hover = null;
    this.state.title = null;
  }

  enterVideo = (e) => {
    const video = e.target.querySelector('video');

    if (video === null) return;

    video.play();

    gsap.to(video, {
      opacity: 1,
    });
  }

  leaveVideo = (e) => {
    const video = e.target.querySelector('video');

    if (video === null) return;

    gsap.to(video, {
      opacity: 0,
    });

    const promise = video.play();

    if (promise !== undefined) {
      promise.then(_ => {
        video.pause();
      })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
