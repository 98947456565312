import Thumbnail from '../thumbnail/thumbnail';

export default class Campaign {
  constructor() {
    this.section = document.getElementById('s-campaign');
  }

  init = () => {
    this.thumbnail = new Thumbnail;
    this.thumbnail.init();
  }

  destroy = () => {
    this.thumbnail.destroy();
  }
}
