import gsap from 'gsap';
import Sniffer from 'sniffer';

import { instances } from '../../utils/store';

export default class Bio {
  constructor() {
    this.section = document.getElementById('h-bio');

    this.data = {
      last: 0,
      ease: 0.1,
      current: 0,
    };

    this.down = this.section.querySelector('.bottom-l');

    this.lerp = (a, b, n) => (1 - n) * a + n * b;
  }

  init() {
    if (Sniffer.isDesktop && instances.ipad === false) {
      this.create();
      this.down.addEventListener('click', this.scroll);
    }

    instances.offset = undefined;
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  scroll = () => {
    if (instances.loading === true) return;
    instances.scroll.data.current = -window.innerHeight;
  }

  render = () => {
    this.data.last = this.lerp(this.data.last, instances.scroll.data.current, this.data.ease);

    this.transform();
  }

  transform = () => {
    this.section.querySelector('.figure').style.transform = `translate3d(0px, ${(Math.abs(this.data.last / 5))}px, 0px)`;
  }
}
