import gsap from 'gsap';

import { instances } from '../../utils/store';

export default class Menu {
  constructor() {
    this.menu = {
      d: document.querySelector('.menu-d'),
      l: document.querySelector('.menu-l'),
      a: document.querySelectorAll('#a-menu a'),
    };

    this.state = {
      hidden: false,
    }

    this.direction = 'down';
  }

  init = () => {
    this.size();
    this.events();
  }

  size = () => {
    this.window = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    if (document.body.classList.contains('is-dark')) {
      gsap.set(this.menu.d, { clip: `rect(0px 0px ${this.window.h}px 0px)`, });
      gsap.set(this.menu.l, { clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`, });
    } else {
      gsap.set(this.menu.l, { clip: `rect(0px 0px ${this.window.h}px 0px)`, });
      gsap.set(this.menu.d, { clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`, });
    }
  }

  events = () => {
    gsap.ticker.add(this.render);

    window.addEventListener('resize', this.size, { passive: true });

    [...this.menu.a].forEach((i) => {
      if (i.href === location.href) {
        i.classList.add('is-active');
      }
    });
  }

  render = () => {
    if (document.body.classList.contains('is-loading')) return;

    if (instances.scroll.scrolling === false) return;

    let d = instances.scroll.data.current;

    if (d > instances.scroll.data.last) {
      if (this.direction === 'down') {
        this.direction = 'up';
      }
    } else {
      if (this.direction === 'up') {
        this.direction = 'down';
      }
    }

    if (instances.player === true) return;

    if (this.direction === 'up' && this.state.hidden === true) {
      this.state.hidden = false;
      gsap.to(this.menu.a, {
        yPercent: 0,
      });
    }

    if (instances.scroll.data.current < 0 && this.direction === 'down') {
      if (this.state.hidden === false) {
        this.state.hidden = true;
        gsap.to(this.menu.a, {
          yPercent: -100,
        });
      }
    } else {
      if (this.state.hidden === true) {
        this.state.hidden = false;
        gsap.to(this.menu.a, {
          yPercent: 0,
        });
      }
    }
  }
}
