import gsap from 'gsap';
import Sniffer from 'sniffer';
import SplitText from 'gsap/SplitText';

// store
import { instances, wrap } from '../../utils/store';

export default class Info {
  constructor() {
    this.dom = {
      video: document.querySelector('video'),
      info: document.getElementById('s-info'),
      player: document.getElementById('s-player'),
      heading: document.querySelector('.heading'),
    }

    this.data = {
      last: 0,
      ease: 0.1,
      current: 0,
    };

    this.animated = false;

    this.lerp = (a, b, n) => (1 - n) * a + n * b;
  }

  init() {
    if (Sniffer.isDevice) {
      return;
    }

    this.create();

    document.fonts.ready.then(() => {
      this.set();
    });
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  set = () => {
    const f5 = new SplitText(this.dom.heading.querySelector('.f-5'), {
      type: 'lines'
    });

    [...f5.lines].forEach((c) => {
      wrap.line(c);
    });

    const f6 = new SplitText(this.dom.heading.querySelector('.f-6'), {
      type: 'lines'
    });

    [...f6.lines].forEach((c) => {
      wrap.line(c);
    });

    this.split = this.dom.heading.querySelectorAll('.line div');

    gsap.set(this.split, {
      yPercent: 102,
    });
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  render = () => {
    this.data.last = this.lerp(this.data.last, instances.scroll.data.current, this.data.ease);

    const y = Math.abs(this.data.last);
    const b = this.dom.info.getBoundingClientRect();

    const d = (window.innerHeight + y) - (b.top + y);
    const p = d / ((window.innerHeight + b.height) / 100);

    const calc = 1 - p * 2 / 100;

    this.dom.player.style.transform = `translate3d(0px, ${Math.abs(this.data.last)}px, 0px)`;

    if (calc <= 0) {
      this.dom.player.style.opacity = 0;
      this.dom.video.volume = 0;
      return;
    };

    this.dom.player.style.opacity = calc;
    this.dom.video.volume = calc;

    if (this.dom.heading.getBoundingClientRect().top < window.innerHeight && this.animated === false) {

      this.animated = true;

      gsap.to(this.split, {
        delay: 0.5,
        yPercent: 0,
        duration: 2,
        stagger: 0.1,
        ease: 'expo.out',
        clearProps: 'all',
      });
    }
  }
}
