import gsap from 'gsap';
import Sniffer from 'sniffer';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(DrawSVGPlugin);

import { instances } from '../../utils/store';

export default class Player {
  constructor() {
    this.player = document.getElementById('s-player');

    this.page = document.querySelector('.a-page');

    this.back = this.player.querySelector('.back');
    this.info = this.player.querySelector('.info');
    this.play = this.player.querySelector('.play');
    this.video = this.player.querySelector('video');
    this.timer = this.player.querySelector('.timer');
    this.range = this.player.querySelector('.range');
    this.cursor = this.player.querySelector('.cursor');
    this.loader = this.player.querySelector('.loader');
    this.progress = this.player.querySelector('.progress');
    this.controls = this.player.querySelector('.controls');

    this.time = 0;
    this.hover = false;
    this.visible = true;
    this.inactive = false;

    this.loading = true;

    this.mouse = {
      tx: window.innerWidth / 2,
      px: window.innerWidth / 2,
      ty: window.innerHeight / 2,
      py: window.innerHeight / 2,
    };

    this.y = {
      current: 0,
    };
  }

  destroy = () => {
    instances.player = false;
    gsap.ticker.remove(this.render);
  }

  init = () => {
    if (Sniffer.isDevice || instances.ipad === true) {
      return;
    }

    instances.player = true;

    this.promise();
    this.spinner();
  }

  promise = () => {
    if (Sniffer.isSafari) {
      this.video.pause();
      this.video.muted = true;
      this.play.innerHTML = 'Play';
      this.events();
    } else {
      this.play.innerHTML = 'Pause';
      const promise = this.video.play();

      if (promise !== undefined) {
        promise.then(_ => {
          this.events();
        })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }

  spinner = () => {
    const path = this.loader.querySelector('.spinner');
    const tl = gsap.timeline({ repeat: -1 });

    gsap.to(this.loader, {
      rotate: '-360',
      repeat: -1,
      duration: 10,
      ease: 'linear',
    })

    tl.to(path, {
      drawSVG: '0% 100%',
    }, 0)
      .to(path, {
        duration: 0.75,
        ease: 'expo.in',
        drawSVG: '0% 0%',
      }, 0)
      .set(path, {
        drawSVG: '100% 100%',
      }, 0.75)
      .to(path, {
        duration: 0.75,
        ease: 'expo.out',
        drawSVG: '0% 100%',
      }, 0.75);
  }

  events = () => {
    this.info.addEventListener('click', this.scroll);
    this.player.addEventListener('mousemove', this.idle);

    this.controls.addEventListener('mouseenter', () => {
      this.hover = true;
      this.hideCursor();
    });

    this.controls.addEventListener('mouseleave', () => {
      this.hover = false;
      this.showCursor();
    });

    window.addEventListener('mousemove', (e) => {
      this.mouse.ty = e.clientY;
      this.mouse.tx = e.clientX;
    });

    gsap.ticker.add(this.render);

    this.range.addEventListener('input', () => {
      this.updateProgress = null;
      this.video.currentTime = this.range.value / 10;
    });

    window.addEventListener('keydown', this.close);

    this.play.addEventListener('click', () => {
      if (this.video.paused) {
        this.video.play();
        this.video.muted = false;
        this.play.innerHTML = 'Pause';
      } else {
        this.video.pause();
        this.video.muted = true;
        this.play.innerHTML = 'Play';
      }
    });
  }

  follower = () => {
    this.bounding = this.back.getBoundingClientRect().top;

    this.mouse.px += ((this.mouse.tx - this.mouse.px) * 0.1);
    this.mouse.py += ((this.mouse.ty - this.mouse.py) * 0.1);

    this.back.style.transform = `translate3d(${this.mouse.px}px, ${this.mouse.py}px, 0px)`;
  }

  close = (e) => {
    if (e.keyCode === 27) {
      window.removeEventListener('keydown', this.close);
      this.video.click();
    }
  }

  scroll = () => {
    instances.scroll.data.current = -window.innerHeight;
  }

  idle = () => {
    clearTimeout(this.time);
    this.time = setTimeout(() => {
      this.hideControls();
      this.hideCursor();
    }, 1500);
    if (Math.abs(this.scroll) > 1) return;
    this.showControls();
    if (this.hover === true) return;
    this.showCursor();
  }

  showCursor = () => {
    gsap.to(this.cursor, {
      opacity: 1,
      duration: 0.5,
    });
  }

  hideCursor = () => {
    gsap.to(this.cursor, {
      opacity: 0,
      duration: 0.5,
    });
  }

  showControls = () => {
    gsap.to(this.controls, {
      opacity: 1,
      duration: 0.5,
    });
  }

  hideControls = () => {
    gsap.to(this.controls, {
      opacity: 0,
      duration: 0.5,
    });
  }

  format = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    minutes = (minutes >= 10) ? minutes : '0' + minutes;
    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : '0' + seconds;
    this.timer.innerHTML = minutes + ':' + seconds;
  }

  render = () => {
    if (!this.video.duration) return;

    this.range.max = Math.round(this.video.duration * 10);
    this.progress.max = Math.round(this.video.duration * 10);

    this.range.value = this.video.currentTime * 10;
    this.progress.value = this.video.currentTime * 10;

    let position = this.range.getBoundingClientRect().width / 100 * this.video.currentTime / this.video.duration * 100;
    this.timer.style.transform = `translateX(${position}px)`;

    let seconds = this.video.currentTime;
    this.format(seconds);

    this.scroll = this.page.getBoundingClientRect().top;

    if (Math.abs(this.scroll) > 1) {
      if (this.visible === true) {
        this.visible = false;
      }
    } else {
      if (this.visible === false) {
        this.visible = true;
        this.hideControls();
        this.hideCursor();
      }
    }

    if (this.video.currentTime > 0 && this.loading === true) {
      this.loading = false;
      gsap.killTweensOf(this.loader);
      gsap.killTweensOf(this.loader.querySelector('.spinner'))
      gsap.to(this.loader, {
        opacity: 0,
      });
    }

    this.follower();
  }
}
