import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import Highway from '@dogstudio/highway';

// store
import { instances, wrap } from '../utils/store';

class Header extends Highway.Transition {
  in({ from, to, done }) {

    this.window = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    this.menu = {
      d: document.querySelector('.menu-d'),
      l: document.querySelector('.menu-l'),
    };

    this.heading = {
      i: to.querySelector('header img'),
      o: from.querySelector('header img'),
    };

    this.mask = {
      i: to.querySelector('header .mask'),
      o: from.querySelector('header .mask'),
    }

    // contact
    if (from.classList.contains('is-contact')) {

      // → work
      if (to.classList.contains('is-work')) {
        const f5 = new SplitText(to.querySelector('.bottom-l .f-5'), {
          type: 'lines'
        });

        const f7 = new SplitText(to.querySelector('.bottom-r .f-7'), {
          type: 'lines'
        });

        const p = new SplitText(to.querySelectorAll('header p'), {
          type: 'lines'
        });

        [...f5.lines].forEach((c) => {
          wrap.line(c);
        });

        [...f7.lines].forEach((c) => {
          wrap.line(c);
        });

        [...p.lines].forEach((c) => {
          wrap.line(c);
        });

        const h = to.querySelector('.heading img');
        const l = to.querySelectorAll('header .line div');

        gsap.set([h, l], {
          yPercent: 102,
        });

        gsap.to([h, l], {
          delay: 0.5,
          yPercent: 0,
          duration: 2,
          stagger: 0.1,
          ease: 'expo.out',
          clearProps: 'all',
        });
      }

      // → bio
      if (to.classList.contains('is-bio')) {

        const p = new SplitText(to.querySelector('.bottom-c p'), {
          type: 'lines'
        });

        [...p.lines].forEach((c) => {
          wrap.line(c);
        });

        const f = to.querySelector('.figure img');
        const h = to.querySelector('.heading img');
        const s = to.querySelectorAll('.bottom-r a');
        const l = to.querySelectorAll('header .line div');

        gsap.set([h, l, s], {
          yPercent: 102,
        });

        gsap.set(f, {
          opacity: 0,
        });

        gsap.to(f, {
          delay: 1,
          opacity: 1,
          duration: 2,
          clearProps: 'all',
        });

        gsap.to([h, l, s], {
          delay: 0.5,
          yPercent: 0,
          duration: 2,
          stagger: 0.1,
          ease: 'expo.out',
          clearProps: 'all',
        });

        gsap.fromTo(this.menu.l,
          {
            clip: `rect(${this.window.h}px ${this.window.w}px ${this.window.h}px 0px)`,
          }, {
          duration: 1.5,
          ease: 'expo.inOut',
          clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
        });

        gsap.fromTo(this.menu.d,
          {
            clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
          }, {
          duration: 1.5,
          ease: 'expo.inOut',
          clip: `rect(0px ${this.window.w}px 0px 0px)`,
        });
      }

      gsap.fromTo(from,
        {
          clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
        }, {
        duration: 1.5,
        clearProps: 'all',
        ease: 'expo.inOut',
        clip: `rect(0px ${this.window.w}px 0px 0px)`,

        onComplete: () => {
          from.remove();
          done();
        },
      }
      );
    }

    // campaign
    if (from.classList.contains('is-campaign')) {

      // → bio
      if (to.classList.contains('is-bio')) {
        gsap.fromTo(this.menu.l,
          {
            clip: `rect(0px 0px ${this.window.h}px 0px)`,
          }, {
          duration: 1.5,
          ease: 'expo.inOut',
          clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
        });

        gsap.fromTo(this.menu.d,
          {
            clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
          }, {
          duration: 1.5,
          ease: 'expo.inOut',
          clip: `rect(0px ${this.window.w}px ${this.window.h}px ${this.window.w}px)`,
        });

        gsap.fromTo(from,
          {
            clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
          }, {
          duration: 1.5,
          clearProps: 'all',
          ease: 'expo.inOut',
          clip: `rect(0px ${this.window.w}px ${this.window.h}px ${this.window.w}px)`,

          onComplete: () => {
            from.remove();
            done();
          },
        }
        );
      }

      // → work
      if (to.classList.contains('is-work')) {
        instances.offset = undefined;
        gsap.fromTo(from,
          {
            clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
          }, {
          duration: 1.5,
          clearProps: 'all',
          ease: 'expo.inOut',
          clip: `rect(0px ${this.window.w}px 0px 0px)`,

          onComplete: () => {
            from.remove();
            done();
          },
        }
        );
      }
    }

    // work
    if (from.classList.contains('is-work')) {

      const p = new SplitText(to.querySelector('.bottom-c p'), {
        type: 'lines'
      });

      [...p.lines].forEach((c) => {
        wrap.line(c);
      });

      const l = to.querySelectorAll('header .line div');
      const s = to.querySelectorAll('header .bottom-r a');

      gsap.set([l, s], {
        yPercent: 102,
      });

      gsap.to([l, s], {
        delay: 0.5,
        yPercent: 0,
        duration: 2,
        stagger: 0.1,
        ease: 'expo.out',
        clearProps: 'all',
      });

      gsap.fromTo(this.menu.l,
        {
          clip: `rect(0px 0px ${this.window.h}px 0px)`,
        }, {
        duration: 1.5,
        ease: 'expo.inOut',
        clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
      });

      gsap.fromTo(this.menu.d,
        {
          clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
        }, {
        duration: 1.5,
        ease: 'expo.inOut',
        clip: `rect(0px ${this.window.w}px ${this.window.h}px ${this.window.w}px)`,
      });

      gsap.fromTo(this.heading.i,
        {
          x: this.window.w / 2,
        }, {
        x: 0,
        duration: 1.5,
        ease: 'expo.inOut',
      });

      gsap.fromTo(this.heading.o,
        {
          x: 0,
        }, {
        duration: 1.5,
        ease: 'expo.inOut',
        x: -this.window.w / 2,
      });

      gsap.fromTo(this.mask.i,
        {
          x: -this.window.w / 3,
        }, {
        x: 0,
        duration: 1.5,
        ease: 'expo.inOut',
      });

      gsap.fromTo(this.mask.o,
        {
          x: 0,
        }, {
        duration: 1.5,
        ease: 'expo.inOut',
        x: this.window.w / 3,
      });

      gsap.fromTo(from,
        {
          clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
        }, {
        duration: 1.5,
        clearProps: 'all',
        ease: 'expo.inOut',
        clip: `rect(0px ${this.window.w}px ${this.window.h}px ${this.window.w}px)`,

        onComplete: () => {
          from.remove();
          done();
        },
      }
      );
    }

    // bio
    if (from.classList.contains('is-bio')) {

      const f5 = new SplitText(to.querySelector('header .f-5'), {
        type: 'lines'
      });

      const f7 = new SplitText(to.querySelector('header .f-7'), {
        type: 'lines'
      });

      const p = new SplitText(to.querySelectorAll('header p'), {
        type: 'lines'
      });

      [...f5.lines].forEach((c) => {
        wrap.line(c);
      });

      [...f7.lines].forEach((c) => {
        wrap.line(c);
      });

      [...p.lines].forEach((c) => {
        wrap.line(c);
      });

      const l = to.querySelectorAll('header .line div');

      gsap.set(l, {
        yPercent: 102,
      });

      gsap.to(l, {
        delay: 0.5,
        yPercent: 0,
        duration: 2,
        stagger: 0.1,
        ease: 'expo.out',
        clearProps: 'all',
      });

      gsap.fromTo(this.menu.l,
        {
          clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
        }, {
        duration: 1.5,
        ease: 'expo.inOut',
        clip: `rect(0px 0px ${this.window.h}px 0px)`,
      });

      gsap.fromTo(this.menu.d,
        {
          clip: `rect(0px ${this.window.w}px ${this.window.h}px ${this.window.w}px)`,
        }, {
        duration: 1.5,
        ease: 'expo.inOut',
        clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
      });

      gsap.fromTo(this.heading.i,
        {
          x: -this.window.w / 2,
        }, {
        x: 0,
        duration: 1.5,
        ease: 'expo.inOut',
      });

      gsap.fromTo(this.heading.o,
        {
          x: 0,
        }, {
        duration: 1.5,
        ease: 'expo.inOut',
        x: this.window.w / 2,
      });

      gsap.fromTo(this.mask.i,
        {
          x: this.window.w / 3,
        }, {
        x: 0,
        duration: 1.5,
        ease: 'expo.inOut',
      });

      gsap.fromTo(this.mask.o,
        {
          x: 0,
        }, {
        duration: 1.5,
        ease: 'expo.inOut',
        x: -this.window.w / 3,
      });

      gsap.fromTo(from,
        {
          clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
        }, {
        duration: 1.5,
        clearProps: 'all',
        ease: 'expo.inOut',
        clip: `rect(0px 0px ${this.window.h}px 0px)`,

        onComplete: () => {
          from.remove();
          done();
        },
      }
      );

    }
  }

  out({ from, done }) {
    done();
  }
}

export default Header;
