import gsap from 'gsap';
import SplitText from 'gsap/SplitText';

// store
import { wrap } from './store';

export default class Effects {
  constructor() {
    this.effect = {
      fade: document.querySelectorAll('[data-effect="fade"] > *'),
      mask: document.querySelectorAll('[data-effect="mask"] > *'),
    }

    this.total = 0;
  }

  init() {
    document.fonts.ready.then(() => {
      this.create();
    });
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  render = () => {
    [...this.effect.fade].forEach((i) => {
      const b = i.getBoundingClientRect();

      if (b.top < window.innerHeight / 1.5) {
        if (i.inview === undefined) {
          i.inview = true;

          const p = new SplitText(i, {
            type: 'lines',
          });

          gsap.set(p.lines, {
            opacity: 0,
            y: 10,
          });

          this.total += 1;

          i.style.visibility = 'inherit';

          gsap.to(p.lines, {
            y: 0,
            opacity: 1,
            duration: 2,
            stagger: 0.1,
            ease: 'expo.out',
            clearProps: 'all',
          });
        }
      }
    });

    [...this.effect.mask].forEach((i) => {
      const b = i.getBoundingClientRect();

      if (b.top < window.innerHeight / 1.5) {
        if (i.inview === undefined) {
          i.inview = true;

          const p = new SplitText(i, {
            type: 'lines'
          });

          [...p.lines].forEach((c) => {
            wrap.line(c);
          });

          const l = i.querySelectorAll('.line div');

          gsap.set(l, {
            yPercent: 102,
          });

          this.total += 1;

          i.style.visibility = 'inherit';

          gsap.to(l, {
            yPercent: 0,
            duration: 2,
            stagger: 0.1,
            ease: 'expo.out',
            clearProps: 'all',
          });
        }
      }
    });

    if (this.total === this.effect.fade.length + this.effect.mask.length) {
      this.destroy();
    }
  }
}
