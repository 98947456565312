import Sniffer from 'sniffer';
import { instances } from '../../utils/store';

export default class Footer {
  constructor() {
    this.dom = {
      top: document.querySelector('#a-footer button'),
    }

    this.scroll = document.querySelector('.a-scroll');
    this.wrapper = document.querySelector('.a-wrapper');
  }

  init = () => {
    this.dom.top.addEventListener('click', () => {
      if (Sniffer.isDevice || instances.ipad === true) {
        if (Sniffer.isSafari) {
          this.wrapper.scrollTop = 0;
        } else {
          this.wrapper.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
      } else {
        instances.scroll.data.current = 0;
      }
    });
  }
}
