// modules
import gsap from 'gsap';
import Highway from '@dogstudio/highway';

// components
import Components from '../components/index';

// utils
import Effects from '../utils/effects';

// renderer
class Renderer extends Highway.Renderer {
  onEnter() {
    const path = '/wp-content/themes/aliali/src/assets/favicon';
    if (window.location.pathname == '/bio/') {
      document.body.classList.add('is-dark');
      document.body.classList.remove('is-light');

      document.getElementById('favicon-touch').href = `${window.location.origin}${path}/apple-touch-icon-dark.png`;
      document.getElementById('favicon-32').href = `${window.location.origin}${path}/favicon-32x32-dark.png`;
      document.getElementById('favicon-16').href = `${window.location.origin}${path}/favicon-16x16-dark.png`;
    } else {
      document.body.classList.add('is-light');
      document.body.classList.remove('is-dark');

      document.getElementById('favicon-touch').href = `${window.location.origin}${path}/apple-touch-icon.png`;
      document.getElementById('favicon-32').href = `${window.location.origin}${path}/favicon-32x32.png`;
      document.getElementById('favicon-16').href = `${window.location.origin}${path}/favicon-16x16.png`;
    }
  }

  onEnterCompleted() {
    document.body.classList.remove('is-loading');

    this.components = new Components();
    this.components.init();

    this.effects = new Effects();
    this.effects.init();

    if (document.querySelector('.a-page').classList.contains('is-player')) return;
    if (document.body.classList.contains('is-preloading')) return;

    gsap.to(document.querySelectorAll('#a-menu a'), {
      yPercent: 0,
    });
  }

  onLeave() {
    document.body.classList.add('is-loading');

    this.components.destroy();
    this.components = undefined;

    this.effects.destroy();
    this.effects = undefined;
  }

  onLeaveCompleted() { }
}

// export
export default Renderer;
