import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import Highway from '@dogstudio/highway';

// store
import { wrap } from '../utils/store';

class Campaign extends Highway.Transition {
  in({ from, to, done }) {

    this.window = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    this.heading = to.querySelector('.f-3');
    this.info = to.querySelector('.info p');
    this.more = to.querySelectorAll('.more .block')
    this.featured = to.querySelector('.featured .block');

    gsap.set(this.heading, {
      yPercent: 100,
    });

    gsap.set([this.featured, this.more], {
      opacity: 0,
      y: this.window.w * 0.1,
    });

    const p = new SplitText(to.querySelector('.info p'), {
      type: 'lines'
    });

    [...p.lines].forEach((c) => {
      wrap.line(c);
    });

    const l = to.querySelectorAll('.info .line div');

    gsap.set(l, {
      yPercent: 102,
    });

    gsap.to(l, {
      delay: 1,
      yPercent: 0,
      duration: 2,
      stagger: 0.1,
      ease: 'expo.out',
      clearProps: 'all',
    });

    gsap.to(this.heading, {
      delay: 1,
      yPercent: 0,
      duration: 2,
      ease: 'expo.out',
      clearProps: 'all',
    });

    gsap.to([this.featured, this.more], {
      y: 0,
      delay: 1.3,
      opacity: 1,
      duration: 2,
      stagger: 0.5,
      ease: 'expo.out',
      clearProps: 'all',
    });

    gsap.fromTo(from,
      {
        clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
      }, {
      duration: 1.5,
      clearProps: 'all',
      ease: 'expo.inOut',
      clip: `rect(0px ${this.window.w}px 0px 0px)`,

      onComplete: () => {
        from.remove();
        done();
      },
    }
    );
  }

  out({ from, done, trigger }) {
    done();
  }
}

export default Campaign;
