import gsap from 'gsap';
import Sniffer from 'sniffer';

import { instances } from '../../utils/store';

export default class Contact {
  constructor() {
    this.dom = {
      page: document.querySelector('.a-page'),
      info: document.querySelectorAll('.location ul'),
      location: document.querySelectorAll('.location'),
    };

    this.header = {
      buttons: document.querySelectorAll('#h-contact .quote a'),
    }

    this.wrapper = document.querySelector('.a-wrapper');
  }

  init() {
    if (this.dom.page.classList.contains('is-contact')) {
      instances.offset = undefined;
      this.scroller();
      return;
    };

    this.values();
    this.set();
    this.events();
  }

  destroy = () => {
    window.removeEventListener('resize', this.resize);
  }

  values = () => {
    this.window = {
      h: window.innerHeight,
      w: window.innerWidth,
    };

    this.height = {
      o: window.innerWidth * 0.17014,
      c: window.innerWidth * 0.04861,
    };

    if (Sniffer.isPhone) {
      this.height = {
        o: window.innerWidth * 0.72222,
        c: window.innerWidth * 0.19444,
      };
    }
  }

  set = () => {
    gsap.set(this.dom.location, {
      height: this.height.c,
    });
  }

  events = () => {
    [...this.dom.location].forEach((i) => {
      i.addEventListener('click', this.toggle);
    });

    window.addEventListener('resize', this.resize, { passive: true });
  }

  resize = () => {
    this.values();
    this.set();
  }

  scroller = () => {
    [...this.header.buttons].forEach((i) => {
      i.addEventListener('click', this.scrollto);
    });
  }

  scrollto = () => {
    setTimeout(() => {
      if (window.location.hash) {
        const hash = window.location.hash.substr(1);
        const height = this.wrapper.getBoundingClientRect().height;
        const location = document.querySelector(`[data-location=${hash}]`);

        let offset = location.getBoundingClientRect().top - instances.scroll.data.last;

        if (Sniffer.isDevice || instances.ipad === true) {
          if (Sniffer.isSafari) {
            this.wrapper.scrollTop = offset;
          } else {
            this.wrapper.scrollTo({
              left: 0,
              top: offset,
              behavior: 'smooth'
            });
          }
        } else {
          if (offset > (height - window.innerHeight)) {
            offset = height - window.innerHeight;
          }
          instances.scroll.data.current = -offset;
        }

        history.replaceState(null, null, ' ');
      }
    });
  }

  toggle = (e) => {
    const location = e.target.closest('.location');
    const info = location.querySelector('ul');

    gsap.to(this.dom.location, {
      duration: 0.5,
      ease: 'expo.out',
      height: this.height.c,
      onComplete: () => {
        instances.scroll.resize();
      }
    });

    gsap.to(this.dom.info, {
      opacity: 0,
      duration: 0.5,
    });

    if (location.classList.contains('is-active')) {
      location.classList.remove('is-active');
      return;
    }

    [...this.dom.location].forEach((i) => {
      i.classList.remove('is-active');
    });

    gsap.to(location, {
      duration: 0.5,
      ease: 'expo.out',
      height: this.height.o,
      onComplete: () => {
        instances.scroll.resize();
      }
    });

    gsap.to(info, {
      opacity: 1,
      duration: 0.5,
    });

    location.classList.add('is-active');
  }
}
