import gsap from 'gsap';

export default class Viral {
  constructor() {
    this.section = document.getElementById('s-viral');

    this.dom = {
      span: this.section.querySelectorAll('.f-1 span'),
    };

    this.inview = null;
  }

  init() {
    this.create();
  }

  create = () => {
    gsap.ticker.add(this.render);
  }

  destroy = () => {
    gsap.ticker.remove(this.render);
  }

  render = () => {
    const b = this.section.getBoundingClientRect();

    if (b.top < window.innerHeight) {
      if (this.inview === null) {
        this.inview = true;

        this.digits = this.dom.span[0].querySelectorAll('i');

        gsap.to(this.dom.span[0], {
          duration: 3,
          ease: 'expo.out',
          y: -this.digits[0].getBoundingClientRect().height * (this.digits.length - 1),
        });

        this.digits = this.dom.span[1].querySelectorAll('i');

        gsap.to(this.dom.span[1], {
          duration: 3,
          ease: 'expo.out',
          y: -this.digits[1].getBoundingClientRect().height * (this.digits.length - 1),
        });

        this.destroy();
      }
    }
  }
}
