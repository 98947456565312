import Sniffer from 'sniffer';

// components
import Awards from './awards/awards';
import Bio from './bio/bio';
import Campaign from './campaign/campaign';
import Contact from './contact/contact';
import Footer from './footer/footer';
import Info from './info/info';
import Player from './player/player';
import Scroll from './scroll/scroll';
import Viral from './viral/viral';
import Work from './work/work';

// instances
import { instances } from '../utils/store';

export default class Components {
  constructor() {
    this.awards = document.getElementById('s-awards');
    this.bio = document.getElementById('h-bio');
    this.campaign = document.getElementById('s-campaign');
    this.contact = document.getElementById('s-contact');
    this.footer = document.getElementById('a-footer');
    this.info = document.getElementById('s-info');
    this.player = document.getElementById('s-player');
    this.viral = document.getElementById('s-viral');
    this.work = document.getElementById('s-work');
  }

  destroy = () => {
    instances.scroll.destroy();

    if (this.awards) { this.awards.destroy(); }
    if (this.bio) { this.bio.destroy(); }
    if (this.campaign) { this.campaign.destroy(); }
    if (this.contact) { this.contact.destroy(); }
    if (this.info) { this.info.destroy(); }
    if (this.player) { this.player.destroy(); }
    if (this.viral) { this.viral.destroy(); }
    if (this.work) { this.work.destroy(); }
  }

  init() {
    instances.scroll = new Scroll();
    instances.scroll.init();

    if (this.awards) {
      this.awards = new Awards();
      this.awards.init();
    }
    if (this.bio) {
      this.bio = new Bio();
      this.bio.init();
    }
    if (this.campaign) {
      this.campaign = new Campaign();
      this.campaign.init();
    }
    if (this.contact) {
      this.contact = new Contact();
      this.contact.init();
    }
    if (this.footer) {
      this.footer = new Footer();
      this.footer.init();
    }
    if (this.info) {
      this.info = new Info();
      this.info.init();
    }
    if (this.player) {
      this.player = new Player();
      this.player.init();
    }
    if (this.viral) {
      this.viral = new Viral();
      this.viral.init();
    }
    if (this.work) {
      this.work = new Work();
      this.work.init();
    }
  }
}
