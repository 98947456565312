export const instances = {
  ipad: false,
  loading: true,
  player: false,
  scroll: undefined,
  offset: undefined,
};

export const math = {
  lerp: (a, b, n) => (1 - n) * a + n * b,
};

export const wrap = {
  line: (e) => {
    e.style.display = '';
    e.style.position = '';
    const d = document.createElement('div');
    d.classList.add('line');
    e.parentNode.insertBefore(d, e);
    d.appendChild(e);
  }
}
